export default {
    meta: {
      title: '飓风季节到了|FloodHelpNY.org',
      url: 'https://www.floodhelpny.org/zh-CN/prepare-for-hurricane-season',
      description:
        '采取四个简单步骤，即刻保护您的房屋和财务免受洪水侵袭'
    },
    header: '飓风季节到了',
    subHeader: '今天采取四个简单步骤，保护您的房屋免受洪水侵袭',
    photos:
      '为您的重要物品拍照，帮助加快保险理赔',
    copies:
      '将重要文件的副本放在防水容器中或保存电子版本',
    move:
      '将贵重物品从地下室移至上层，防止进水损坏',
    insurance:
      '购买洪水保险或查看您当前的保单，确保获得最新信息',
    posterCallToAction: '获取发布消息',
    posterCopy: '在这里下载并打印您的清单副本。',
    posterButton: '里下载',
    posterUrl: '/samples/FloodHelpNY-Prepare-Poster-2025-small.pdf',
    camera: '照相机',
    waves: '波浪'
  };
  