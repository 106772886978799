export default {
  meta: {
    title: 'La temporada de huracanes está aquí | FloodHelpNY.org',
    url: 'https://www.floodhelpny.org/es/prepare-for-hurricane-season',
    description:
      'Tome hoy cuatro pasos sencillos para proteger su hogar y sus finanzas de las inundaciones.'
  },
  header: 'Ha llegado la temporada de huracanes.',
  subHeader:
    'Dé cuatro fáciles pasos hoy mismo para proteger su hogar y sus finanzas de las inundaciones:',
  photos:
    '<b>Tome fotos</b> de sus pertenencias importantes para ayudar a acelerar los reclamos de seguros.',
  copies:
    '<b>Ponga copias</b> de documentos importantes en un contenedor hermético o <b>guárdelos</b> electrónicamente.',
  move:
    '<b>Traslade</b> los artículos valiosos del sótano a los pisos superiores para prevenir los daños causados por el agua.',
  insurance:
    '<b>Compre</b> seguro de inundaciones o <b>examine</b> su póliza actual para asegurarse de que está al día.',
  posterCallToAction: 'Consigue el póster',
  posterCopy:
    'Descargue e imprima su propia copia de la lista de verificación aquí.',
  posterButton: 'Descargar',
  posterUrl: '/samples/FloodHelpNY-Prepare-Poster-2025-small.pdf',
  camera: 'Cámara',
  waves: 'Olas'
};
